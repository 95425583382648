/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useEffect} from "react"
import PropTypes from "prop-types"

import Header from "./header/header"
import Footer from "./footer/footer"
import Cursor from "./cursor/cursor"
import "./layout.scss"
import Scrollbar from 'smooth-scrollbar'
import { motion } from "framer-motion"


const Layout = ({ children }) => {

  useEffect(()=> {
    const elem = document.getElementById('layout');
    var scrollbar = Scrollbar.init(elem, {damping: 0.2, syncCallbacks: true});
    var cover = document.getElementById('cover');
    scrollbar.addListener(({ offset }) => {
      cover.style.backgroundPosition = `50% calc(50% + ${offset.y / 4}px)`;
    });
  },[]);

  return (
    <>
    <Cursor /> 
    <div id={`layout`} data-scrollbar>
      <Header siteTitle={`Home`} />
      <div>
      <main>{children}</main>
      </div>
      <Footer />
    </div>
    </>
  )};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
