import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import Styles from './header.module.scss'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import gsap from "gsap"
import classNames from 'classnames/bind'

let cx = classNames.bind(Styles);

const Links = ({open}) => (
  <>
  <div className={cx(`link`, `data-hover`, open ? `hide` : null)}><Link to="/services">Services</Link></div>
  <div className={cx(`link`, `data-hover`, open ? `hide` : null)}><Link to="/projects">Projects</Link></div>
  <div className={cx(`link`, `data-hover`, open ? `hide` : null)}><Link to="/about">About</Link></div>
  <div className={cx(`link`, `data-hover`, open ? `hide` : null)}><Link to="/connect">Connect</Link></div>
  </>
)

const Nav = ({open}) => {
  return (
  <nav className={open ? Styles.open : null}>
    <div className={Styles.links}>
      <div className={`content`}>
        <div className={Styles.cols}>
        <div className={Styles.colWrap}>
          <div className={Styles.heading}>Navigate</div>
          <div className={cx(`link`,`data-hover`,`nav-link`)}><Link to="/">Home</Link></div>
          <div className={cx(`link`,`data-hover`,`nav-link`)}><Link to="/about">About</Link></div>
          <div className={cx(`link`,`data-hover`,`nav-link`)}><Link to="/projects">Projects</Link></div>
          <div className={cx(`link`,`data-hover`,`nav-link`)}><Link to="/services">Services</Link></div>
        </div>
        <div className={Styles.colWrap}>
          <div className={Styles.heading}>Connect</div>
          <div className={cx(`link`,`data-hover`,`nav-link`)}><Link to="/connect">Work with us</Link></div>
          <div className={cx(`link`,`data-hover`,`nav-link`)}><Link to="/careers">Careers</Link></div>
          <div className={cx(`link`,`data-hover`,`nav-link`)}><Link to="/blog">Blog</Link></div>
        </div>
      </div>
      </div>
    </div>
  </nav>
)
}

const Header = () => {

  const [nav, setNav] = useState(false);

  const toggleNav = () => {
    setNav(!nav);
    if(nav) {

    } else {
      const links = document.querySelectorAll('.nav-link');
      gsap.from(links, {
        y: 20,
        opacity: 0,
        duration: 0.6,
        delay: 0.5,
        ease: "Power3.easeOut",
        stagger: 0.075
      });
    }
  }

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Creavo Logo White.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
  <>
  <header>
    <div className={`content`}>
      <div className={Styles.wrapper}>
      <div className={Styles.logo}>
        <Link
          to="/"
          style={{margin: 0}}
        >          
          <Img fluid={data.placeholderImage.childImageSharp.fluid} />          
        </Link>
      </div>
      <div className={Styles.links}>
        <Links open={nav}/>
        <div className={cx(`hamburger`, `data-hover`, nav ? `close` : null)} onClick={()=>{toggleNav()}}>
          <div class={Styles.top}></div>
          <div class={Styles.bottom}></div>
        </div>
      </div>
      </div>
    </div>
  </header>
  <Nav open={nav}/>
  </>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
