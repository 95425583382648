import React, {useState, useEffect, useRef} from "react"
import Styles from "./cursor.module.scss"
import {lerp, getMousePos} from './utils'
import { gsap } from "gsap";
import classNames from 'classnames/bind'

let cx = classNames.bind(Styles);

const Cursor = () => {

    const cursorElem = useRef(null);

    const current = {x: 0, y: 0};
    const prev = {x: 0, y: 0};

    const render = () => {    
        prev.x = lerp(prev.x, current.x, 0.15);
        prev.y = lerp(prev.y, current.y, 0.15);
        document.getElementById('cursor').style.left = `${prev.x}px`;
        document.getElementById('cursor').style.top = `${prev.y}px`;
        requestAnimationFrame(render);
    }

    const mouseMove = (e) => {
        let mouse = getMousePos(e);
        current.x = mouse.x;
        current.y = mouse.y;
    }
    
    useEffect(()=> {
        window.addEventListener("mousemove", mouseMove);
        var animation = requestAnimationFrame(render);
        
        const scaleCursor = (amt) => {
            gsap.to(cursorElem.current, {
                duration: 0.4,
                scale: amt,
                ease: "Power3.easeOut",
            });
        }

        scaleCursor(0.15);

        gsap.from('#cursor', {
            opacity: 0,
            ease: "Power3.easeOut",
            duration: 2,
            delay: 1,
        });

        const hoverItems = document.querySelectorAll('.data-hover');
        hoverItems.forEach(item => {
            item.addEventListener('mouseenter', () => {
                scaleCursor(1);
            });

            item.addEventListener('mouseleave', () => {
                scaleCursor(0.15);
            });
        })

        return () => {
            window.removeEventListener("mousemove", mouseMove)
            cancelAnimationFrame(animation);
        }
    },[]);

    return (
        <div id="cursor" ref={cursorElem} className={cx(`cursor`, `mediaBlend`)} style={{}}></div>
    )
}

export default Cursor