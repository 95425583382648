import React from "react"
import Styles from "./footer.module.scss"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Button from '../button/button'
import cx from 'classnames'

const Footer = () => {

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Creavo Logo Grey.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <section className={`fix-bottom`}>
        <div className={`content`}>
          <div className={cx(`split-wrapper`,`align-top`)}>
            <div className={`split`}>
              <div className={Styles.leftText}>
              <h2 style={{margin: '0', marginBottom: '.25rem', width: '100%'}}>Ready to talk?</h2>
              <h2 style={{margin: '0', marginBottom: '.25rem', width: '100%'}}>We're right here</h2>
              <div style={{marginTop: '1rem', width: '100%'}} className={Styles.footerButton}><Button dark={true}>Write to us</Button></div>
              </div>
            </div>
            <div className={`split`}>
              <div className={Styles.quickLinks}>
                <div className={Styles.col}>
                  <div className={Styles.title}>
                    Navigate
                  </div>
                  <Link className={`data-hover`}>About Us</Link>
                  <Link className={`data-hover`}>Services</Link>
                  <Link className={`data-hover`}>Projects</Link>
                  <Link className={`data-hover`}>Blog</Link>
                </div>
                <div className={Styles.col}>
                  <div className={Styles.title}>
                    Connect
                  </div>
                  <Link className={`data-hover`}>Work with us</Link>
                  <Link className={`data-hover`}>Careers</Link>
                </div>
              </div>
            </div>
        </div>
        </div>
      </section>
      <footer>
        <div className={`content`}>
        <div className={Styles.wrapper}>
          <div className={Styles.logo}>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
          </div>
          <span>© <a href="https://www.creavo.co">Creavo</a> {new Date().getFullYear()} | All Rights Reserved.</span>
        </div>
        </div>
      </footer>
    </>
  )
}

export default Footer