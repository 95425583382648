import React from "react"
import PropTypes from "prop-types"
import Styles from "./button.module.scss"
import classNames from 'classnames/bind'

let cx = classNames.bind(Styles);

const Button = ({children, dark, variant}) => {
  if(variant === 'default'){
  return (
    <button className={cx(`data-hover`, dark ? `dark` : null)}>{children}</button>
  )} else if(variant === 'filled'){
    return (
      <button className={cx(`filled`, `data-hover`)}>{children}</button>
    )
  }
}

Button.propTypes = {
  dark: PropTypes.bool,
  variant: PropTypes.string
}

Button.defaultProps = {
  dark: false,
  variant: 'default'
}

export default Button